<template>
    <div>
      <b-card border-variant="danger" v-if="errore_caricamento">
        <b-row class="match-height">
          <b-col
            md="8"
          >
            <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
            <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
          </b-col>
          <b-col 
            md="4"
            class="text-center"
          >
            <b-button
            variant="primary"
            :to="{ name: 'home-agentscout' }"
            >
                <feather-icon
                    icon="CornerUpLeftIcon"
                    size="16"
                    class="mr-1"
                />Home
            </b-button>
          </b-col>
        </b-row>
          
      </b-card>
          
      <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner  v-if="errore_caricamento == false"
            variant="primary"
            style="width: 3rem; height: 3rem;"
            label="loading"
        />
        <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
      </div>
  
      <b-card bg-variant="Default" v-if="Caricato">
        <b-card-body class="px-0 py-0">
          <b-tabs pills>
            <b-tab active @click="onTabChangeAbbonamenti">
              <template #title>
                <feather-icon
                icon="AwardIcon"
                size="16"
                class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Abbonamenti</span>
              </template>
              <b-row class="match-height">
                <b-col
                  md="11"
                  cols="10" 
                  class="mb-2"
                >
                  <!-- input search -->
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-form-select style="border-top-right-radius:0px; border-bottom-right-radius:0px;" v-model="campiRicerca.sezione" :options="options_ricerca" disabled-field="notEnabled"></b-form-select>
                    </b-input-group-prepend>
                      <b-form-input v-model="campiRicerca.nome"
                          placeholder="ricerca generale"
                          type="text" />
                    <b-input-group-append>
                      <b-button variant="outline-primary" @click.prevent="ricercaGenerale('Abbonamento')">
                        <feather-icon icon="SearchIcon" />
                      </b-button>
                    </b-input-group-append>
        
                  </b-input-group>
                </b-col>
                <b-col
                  md="1"
                  cols="2"
                  class="mb-2"
                >
                  <b-button variant="outline-primary" class="px-0" @click.prevent="clickReset('Abbonamento')" block v-if="resetState != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
                </b-col>
                
              </b-row>
      
              <!-- table -->
              <vue-good-table
              mode="remote"
              ref="table_Abbonamento"
              @on-page-change="onPageChange_Abbonamento"
              @on-sort-change="onSortChange_Abbonamento"
              @on-column-filter="onColumnFilter_Abbonamento"
              @on-per-page-change="onPerPageChange_Abbonamento"
              :totalRows="totalRecords"
              :rows="rows"
              :columns="columns"
              :isLoading=isLoading
              :search-options="{ 
                  enabled: true,
                  externalQuery: searchTerm }"
              :pagination-options="{
                  enabled: true,
                  perPage:pageLength
              }"
              >
              
              <template slot="loadingContent">
                <div class="text-center my-3">
                    <b-spinner
                      variant="primary"
                      style="width: 3rem; height: 3rem;"
                      label="loading"
                    />
                    <div class="d-block pt-1 text-primary">Caricamento</div>
                </div>
              </template>
              
              <div slot="emptystate"> <div class="text-center py-4"> Nessuna licenza presente</div> </div>
      
              <template
                  slot="table-row"
                  slot-scope="props"
              >

                  <!-- Column: company_name -->
                  <div v-if="props.column.field === 'customer.billing.company_name'">
                    <a @click="$router.push({ name: 'crm-customers-show', params: { id_riga: props.row.customer.id }})">{{ props.row.customer.billing.company_name }}</a>   
                  </div>

                  <!-- Column: Price -->
                  <span v-else-if="props.column.field === 'price'">
                    {{ prezzoFormattato(props.formattedRow[props.column.field]) }}
                  </span>

                  <span v-else-if="props.column.field === 'status.name'">
                    <b-badge :style="colourBackgroundLicense(props.row.status.colore)">
                      {{ props.row.status.name }}
                    </b-badge>
                  </span>

                  <!-- Column: azioni -->
                  <span v-else-if="props.column.field === 'azioni'">
                    <div class="text-nowrap">
                      <feather-icon
                        icon="ArrowRightIcon"
                        class="cursor-pointer text-primary"
                        size="21"
                        v-b-tooltip.hover.v-primary
                        title="Azioni"
                        :id="`tabellautenti-riga-${props.row.id}-sidebar-azioni`"
                        @click="sidebarAction(props.row.id, props.row.ref_code, props.row.customer.billing.company_name, props.row.status.name, props.row.status.colore, props.row.type, props.row.price, props.row.contract, props.row.user.stripe_id, props.row.subscription.ends_at)"
                      />
                    </div>
                  </span>
      
                  <!-- Column: standard -->
                  <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                  </span>
              </template>
      
              <!-- pagination -->
              <template
                  slot="pagination-bottom"
                  slot-scope="props"
              >
                  <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">
                      Visualizzazione da 1 a 
                      </span>
                      <b-form-select
                      v-model="pageLength"
                      :options="['10','25','50','100']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap "> di {{ props.total }} righe </span>
                  </div>
                  <div>
                      <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                      <template #prev-text>
                          <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                          />
                      </template>
                      <template #next-text>
                          <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                          />
                      </template>
                      </b-pagination>
                  </div>
                  </div>
              </template>
              </vue-good-table>
            </b-tab>

            <b-tab @click="onTabChangeOneShot">
              <template #title>
                  <feather-icon
                  icon="AwardIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">OneShot</span>
              </template>

              <b-row class="match-height">
                <b-col
                  md="11"
                  cols="10" 
                  class="mb-2"
                >
                  <!-- input search -->
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-form-select style="border-top-right-radius:0px; border-bottom-right-radius:0px;" v-model="campiRicerca_oneshot.sezione" :options="options_ricerca_oneshot" disabled-field="notEnabled"></b-form-select>
                    </b-input-group-prepend>
                      <b-form-input v-model="campiRicerca_oneshot.nome"
                          placeholder="ricerca generale"
                          type="text" />
                    <b-input-group-append>
                      <b-button variant="outline-primary" @click.prevent="ricercaGenerale('OneShot')">
                        <feather-icon icon="SearchIcon" />
                      </b-button>
                    </b-input-group-append>
        
                  </b-input-group>
                </b-col>
                <b-col
                  md="1"
                  cols="2"
                  class="mb-2"
                >
                  <b-button variant="outline-primary" class="px-0" @click.prevent="clickReset('OneShot')" block v-if="resetState_oneshot != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
                </b-col>
                
              </b-row>
              
              <vue-good-table
                mode="remote"
                ref="table_OneShot"
                @on-page-change="onPageChange_OneShot"
                @on-sort-change="onSortChange_OneShot"
                @on-column-filter="onColumnFilter_OneShot"
                @on-per-page-change="onPerPageChange_OneShot"
                :totalRows="totalRecords_oneshot"
                :columns="columns_oneshot"
                :rows="rows_oneshot"
                :isLoading=isLoading
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm_oneshot }"
                :pagination-options="{
                    enabled: true,
                    perPage:pageLength_oneshot
                }"
                >
              
                <template slot="loadingContent">
                  <div class="text-center my-3">
                      <b-spinner
                        variant="primary"
                        style="width: 3rem; height: 3rem;"
                        label="loading"
                      />
                      <div class="d-block pt-1 text-primary">Caricamento</div>
                  </div>
                </template>
                
                <div slot="emptystate"> <div class="text-center py-4"> Nessuna licenza presente</div> </div>
                
                <template
                    slot="table-row"
                    slot-scope="props"
                >

                  <span v-if="props.column.field === 'status.name'">
                    <b-badge :style="colourBackgroundLicense(props.row.status.colore)">
                      {{ props.row.status.name }}
                    </b-badge>
                  </span>

                    <!-- Column: Price -->
                    <span v-else-if="props.column.field === 'price'">
                      {{ prezzoFormattato(props.formattedRow[props.column.field]) }}
                    </span>

                    <!-- Column: azioni -->
                    <span v-else-if="props.column.field === 'azioni'">
                      <div class="text-nowrap">
                        <feather-icon
                          icon="ArrowRightIcon"
                          class="cursor-pointer text-primary"
                          size="21"
                          v-b-tooltip.hover.v-primary
                          title="Azioni"
                          :id="`tabella-oneshot-riga-${props.row.id}-sidebar-azioni`"
                          @click="sidebarAction(props.row.id, props.row.ref_code, props.row.customer.billing.company_name, props.row.status.name, props.row.status.colore, props.row.type, props.row.price, props.row.contract, '')"
                        />
                      </div>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template
                    slot="pagination-bottom"
                    slot-scope="props"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap">
                        Visualizzazione da 1 a 
                        </span>
                        <b-form-select
                        v-model="pageLength_oneshot"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap "> di {{ props.total }} righe </span>
                    </div>
                    <div>
                        <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength_oneshot"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                        <template #prev-text>
                            <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                            />
                        </template>
                        </b-pagination>
                    </div>
                    </div>
                </template>
                </vue-good-table>

            </b-tab>

          </b-tabs>
        </b-card-body>
      </b-card>
  
      <b-sidebar
        ref="sidebar_azioni"
        id="sidebar-right"
        bg-variant="white"
        :title="infoSideBar.titolo"
        right
        backdrop
        shadow
      >
        <div>
          <div class="py-0 px-2">
            
            <div class="divider">
                <div class="divider-text text-primary fw-bolder">DETTAGLI</div>
            </div>
            <b-row cols="1">
              <b-col class="riga_sidebar">
                <span class="font-weight-bold">Cliente:</span> {{ infoSideBar.customer_name }}
              </b-col>
              <b-col class="riga_sidebar">
                <span class="font-weight-bold" style="margin-right:5px;">Stato Licenza:</span> 
                <b-badge :style="colourBackgroundLicense(infoSideBar.status_colore)">{{ infoSideBar.status_name }}</b-badge>
              </b-col>
              <b-col class="riga_sidebar">
                <span class="font-weight-bold">Tipo:</span> {{ infoSideBar.type }}
              </b-col>
              <b-col class="riga_sidebar">
                <span class="font-weight-bold">Prezzo:</span> {{ prezzoFormattato(infoSideBar.price) }}
              </b-col>
              <b-col class="riga_sidebar">
                <span class="font-weight-bold">Stripe:</span> <a :href="infoSideBar.link_stripe" target="_blank">Scheda Cliente</a>
              </b-col>
            </b-row>

            <div v-if="((infoSideBar.contract_id != '') && (infoSideBar.contract_accepted_at != ''))">
              <div class="divider">
                  <div class="divider-text text-primary fw-bolder">CONTRATTO</div>
              </div>
              <b-row cols="1">
                <b-col>
                  <span class="font-weight-bold">Contratto:</span>  {{ infoSideBar.contract_id }}
                </b-col>
                <b-col>
                  <span class="font-weight-bold" style="padding-right: 5px;">Stato contratto:</span>  
                  <b-badge variant="success" v-if="infoSideBar.contract_accepted_at != null">Firmato</b-badge> 
                  <b-badge variant="light-danger" v-else>Da Firmare</b-badge>
                </b-col>
                <b-col v-if="infoSideBar.type == 'Abbonamento'">
                  <span class="font-weight-bold">Data Rinnovo Tacito:</span>  {{ infoSideBar.data_rinnovo_tacito }}
                </b-col>
              </b-row>
            </div>

            <div class="divider">
              <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
            </div>
            
            <div v-if="infoSideBar.status_name == 'Creata'">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger" block
                class="mb-1"
                @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,60,'error','Sei sicuro di voler cancellare la licenza?')"
              >
                <feather-icon icon="Trash2Icon" /> Cancella Licenza
              </b-button>
            </div>

            <div v-if="infoSideBar.type == 'Abbonamento'">
              <div v-if="infoSideBar.status_name == 'Confermata'">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger" block
                  class="mb-1"
                  @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,60,'error','Sei sicuro di voler revocare la licenza?')"
                >
                  <feather-icon icon="Trash2Icon" /> Revoca Licenza
                </b-button>
              </div>
              <div v-else-if="infoSideBar.status_name == 'Attiva'">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-warning" block
                  class="mb-1"
                  @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,40,'warning','Sei sicuro di voler sospendere la licenza?')"
                >
                    <feather-icon icon="RefreshCwIcon" /> Sospendi Licenza
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger" block
                  class="mb-1"
                  @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,60,'error','Sei sicuro di voler revocare la licenza?')"
                >
                  <feather-icon icon="Trash2Icon" /> Revoca Licenza
                </b-button>
              </div>
              <div v-else-if="infoSideBar.status_name == 'Sospesa'">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-warning" block
                  class="mb-1"
                  @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,30,'warning','Sei sicuro di voler ri-attivare la licenza?')"
                >
                    <feather-icon icon="RefreshCwIcon" /> Riattiva Licenza
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger" block
                  class="mb-1"
                  @click="cambioStatoLicenza(infoSideBar.type,infoSideBar.id,60,'error','Sei sicuro di voler revocare la licenza?')"
                >
                  <feather-icon icon="Trash2Icon" /> Revoca Licenza
                </b-button>
              </div>
              <div v-else>
                
              </div>
            </div>

            <b-button
              v-if="infoSideBar.contract_url_full_file_pdf"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary" block
              class="mb-1"
              :href="infoSideBar.contract_url_full_file_pdf" target="_blank"
            ><feather-icon icon="DownloadIcon" /> Scarica Contratto</b-button>

          </div>
  
        </div>
      </b-sidebar>
    </div>
  </template>
  
  <script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    BTabs, BTab, BCard, BCardBody, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, VBTooltip
  } from 'bootstrap-vue'
  import { VueGoodTable } from 'vue-good-table'
  import Ripple from 'vue-ripple-directive'
  import moment from 'moment'
  import { prezzoFormattato } from '@core/utils/funzioniGlobali' 
  
  export default {
    components: {
      BCardCode,
      VueGoodTable,
      BTabs,
      BTab,
      BCard, 
      BCardBody, 
      BCardText,
      BRow, 
      BCol, 
      BBadge,
      BPagination,
      BSpinner,
      BInputGroup,
      BFormGroup,
      BFormInput,
      BInputGroupPrepend,
      BInputGroupAppend,
      BDropdownDivider,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      BSidebar,
      BButton,
    },
    setup() {
        return {
            prezzoFormattato,
        }
    },
    directives: {
      'b-toggle': VBToggle,
      'b-tooltip': VBTooltip,
      Ripple,
    },
    data() {
      return {
        isLoading: true,
        emptystate: true, 
        pageLength: 10,
        dir: false,
        columns: [
          {
            label: 'Licenza',
            field: 'ref_code',
            width: '100px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Cliente',
            field: 'customer.billing.company_name',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Stato',
            field: 'status.name',
            width: '130px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Inizio',
            field: 'subscription.created_at',
            width: '110px',
            formatFn: function (value) {
                return value != null ? moment(String(value)).locale('it').format('DD/MM/YYYY') : '---'
            },
            filterOptions: {
              enabled: false,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Fine',
            field: 'subscription.ends_at',
            width: '110px',
            formatFn: function (value) {
                return value != null ? moment(String(value)).locale('it').format('DD/MM/YYYY') : '---'
            },
            filterOptions: {
              enabled: false,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Prezzo',
            field: 'price',
            width: '120px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
  
          {
            label: '',
            field: 'azioni',
            sortable: false,
            tdClass: 'text-center',
          },
        ],
        rows: [],
        searchTerm: '',
        resetState: 'hidden',
        totalRecords: 0,
        serverParams: {
          columnFilters: {
            /*
            "customer_id": '1',
            "status.id": {"operator": "=", "value": 20 }
            */
            "type":"Abbonamento",
            "deleted_at": {"operator": "=", "value": null }  
          },
          sort: [
              {
              field: 'id',
              type: 'desc'
              }
          ],
          page: 1, 
          perPage: 10
        },
        startServerParams: {
          columnFilters: {
            /*
            "customer_id": '1',
            "status.id": {"operator": "=", "value": 20 }
            */
            "type":"Abbonamento",
            "deleted_at": {"operator": "=", "value": null }
          },
          sort: [
              {
              field: 'id',
              type: 'desc'
              }
          ],
          page: 1, 
          perPage: 10
        },
        options_ricerca: [
          { value: null, text: 'seleziona...' },
          { value: 'ref_code', text: 'Licenza' },
          { value: 'customer.billing.company_name', text: 'Cliente' },
          { value: 'subscription.created_at', text: 'Data d\'inizio' },
          { value: 'status.name', text: 'Stato' }
        ],
        campiRicerca: {
          nome: '',
          sezione: null,
        },

      /* goodtable per OneShot */
      show_loader_oneshot: true, 
      pageLength_oneshot: 10,
      totalRecords_oneshot: 0,
      columns_oneshot: [
        {
          label: 'ID Licenza',
          field: 'ref_code',
          width: '100px',
          filterOptions: {
            enabled: true,
          },
        },
          {
            label: 'Cliente',
            field: 'customer.billing.company_name',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
        {
          label: 'Stato',
          field: 'status.name',
          width: '130px',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: 'Inizio',
          field: 'oneshot.started_at',
          width: '110px',
          formatFn: function (value) {
              return value != null ? moment(String(value)).locale('it').format('DD/MM/YYYY') : '---'
          },
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Fine',
          field: 'oneshot.expires_at',
          width: '110px',
          formatFn: function (value) {
              return value != null ? moment(String(value)).locale('it').format('DD/MM/YYYY') : '---'
          },
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Prezzo',
          field: 'price',
          width: '120px',
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: '',
          field: 'azioni',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      rows_oneshot: [],
      
      searchTerm_oneshot: '',
      resetState_oneshot: 'hidden',
      serverParams_oneshot: {
        columnFilters: {
          "type":"OneShot",
          "deleted_at": {"operator": "=", "value": null }
        },
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      startServerParams_oneshot: {
        columnFilters: {
          "type":"OneShot",
          "deleted_at": {"operator": "=", "value": null }
        },
        sort: [
            {
            field: 'id',
            type: 'desc'
            }
        ],
        page: 1, 
        perPage: 10
      },
      options_ricerca_oneshot: [
        { value: null, text: 'seleziona...' },
        { value: 'ref_code', text: 'Licenza' },
        { value: 'customer.billing.company_name', text: 'Cliente' },
        { value: 'oneshot.started_at', text: 'Data d\'inizio' },
        { value: 'oneshot.expires_at', text: 'Data di fine' },
        { value: 'status.name', text: 'Stato' }
      ],
      campiRicerca_oneshot: {
        nome: '',
        sezione: null,
      },
      /* goodtable per OneShot */

        infoSideBar: {
          id: '', 
          ref_code: '', 
          customer_name: '',
          status_name: '', 
          status_colore: '', 
          type: '', 
          price: '', 
          contract_id: '', 
          contract_accepted_at: '',
          contract_url_full_file_pdf: '',
          data_rinnovo_tacito: '',
        },
        listSourceOption: [ ],
        listStatusOption: [ ],
        Caricato: false,
        errore_caricamento: false,
        windowWidth: 0,
      }
    },
    mounted() {
        //in caso di resize del browser -> ricalcolo della larghezza della finestra
        window.onresize = () => {
            this.checkWindowsSizeAndResponsiveGoodTable();
        }
    },
    created() {
        this.checkWindowsSizeAndResponsiveGoodTable();

        //caricamento dei dati
        this.loadItems('Abbonamento');

        this.Caricato = true;
        this.errore_caricamento = false;
    },
    methods: {
      
      colourBackgroundLicense(codice_colore) {
        return 'background: '+codice_colore+' !important';
      },

      onTabChangeAbbonamenti() {
        /* caricare dati Abbonamento */
        this.loadItems("Abbonamento");
      },
      onTabChangeOneShot(){
        /* caricare dati One Shot */
        this.loadItems("OneShot");
      },

      checkWindowsSizeAndResponsiveGoodTable(){
        this.windowWidth = window.innerWidth
  
        if(window.innerWidth <1000){
          this.$set(this.columns[3], 'hidden', true)
          this.$set(this.columns[4], 'hidden', true)
        } else if(window.innerWidth <1200){
          this.$set(this.columns[3], 'hidden', false)
          this.$set(this.columns[4], 'hidden', false)
        } else if(window.innerWidth <1400){
          this.$set(this.columns[3], 'hidden', false)
          this.$set(this.columns[4], 'hidden', false)
        } else {
          //tutte le colonne visualizzate
          this.$set(this.columns[3], 'hidden', false)
          this.$set(this.columns[4], 'hidden', false)
        }
      },
      ricercaGenerale(tipo){
        if(
          (this.campiRicerca.sezione !== null) && (this.campiRicerca.sezione !== 'undefined')
          ||
          (this.campiRicerca_oneshot.sezione !== null) && (this.campiRicerca_oneshot.sezione !== 'undefined')
        ){
          console.log("ok ricerca valida -> in: "+tipo)
          
          let valore_ricerca = '';

          if(tipo == 'Abbonamento'){
            //conversione data nel formmato per la ricerca
            if(this.campiRicerca.sezione == 'subscription.created_at'){
              console.log("subscription.created_at PRIMA -> "+this.campiRicerca.nome)

              valore_ricerca = moment(this.campiRicerca.nome, ["DD/MM/YYYY"]).format("YYYY-MM-DD");

              console.log("subscription.created_at DOPO -> "+valore_ricerca)
            } else {
              //altri campi che NON hanno bisogno di pre-formattazione
              valore_ricerca = this.campiRicerca.nome;
            }
    
            //aggiorna parametri con la stessa funzione del filtro colonna ;-)
            this.updateParams(tipo, {
              columnFilters: {
                type: tipo,
                [this.campiRicerca.sezione]: valore_ricerca
              },
            });
          } else {
            //conversione data nel formmato per la ricerca
            if(this.campiRicerca_oneshot.sezione == 'oneshot.started_at'){
              valore_ricerca = moment(this.campiRicerca_oneshot.nome, ["DD/MM/YYYY"]).format("YYYY-MM-DD");
            } else if(this.campiRicerca_oneshot.sezione == 'oneshot.expires_at'){
              valore_ricerca = moment(this.campiRicerca_oneshot.nome, ["DD/MM/YYYY"]).format("YYYY-MM-DD");
            } else {
              //altri campi che NON hanno bisogno di pre-formattazione
              valore_ricerca = this.campiRicerca_oneshot.nome;
            }
    
            //aggiorna parametri con la stessa funzione del filtro colonna ;-)
            this.updateParams(tipo, {
              columnFilters: {
                type: tipo,
                [this.campiRicerca_oneshot.sezione]: valore_ricerca
              },
            });
          }
  
          //forza reset filtro colonne
          if(tipo == 'Abbonamento'){
            this.$refs.table_Abbonamento.reset();
          } else {
            this.$refs.table_OneShot.reset();
          }
  
          this.loadItems(tipo);
  
          //attiva bottone "reset"
          if(tipo == 'Abbonamento'){
            this.resetState = 'attiva';
          } else {
            this.resetState_oneshot = 'attiva';
          }
        }
      },
      clickReset(tipo_licenza){
        console.log("clickReset -> "+tipo_licenza);
  
        if(tipo_licenza == 'Abbonamento'){
          //forza reset filtro colonne
          this.$refs.table_Abbonamento.reset();
    
          //forza reset parametri iniziali della tabella
          this.updateParams(tipo_licenza, this.startServerParams);
          this.loadItems(tipo_licenza);
    
          //reset campi ricerca
          this.campiRicerca.nome = '';
          this.campiRicerca.sezione = null;
          //disattiva bottone "reset"
          this.resetState = 'hidden';
        } else {
          //forza reset filtro colonne
          this.$refs.table_OneShot.reset();
    
          //forza reset parametri iniziali della tabella
          this.updateParams(tipo_licenza, this.startServerParams_oneshot);
          this.loadItems(tipo_licenza);
    
          //reset campi ricerca
          this.campiRicerca_oneshot.nome = '';
          this.campiRicerca_oneshot.sezione = null;
          //disattiva bottone "reset"
          this.resetState_oneshot = 'hidden';
        }
      },
      advanceFilter_STOP(val) {
        //this.searchTerm = val
  
        this.updateParams(val);
        this.loadItems();
      },
      sidebarAction(id, ref_code, customer_name, status_name, status_colore, type, price, contract_array, stripe_id, data_ends_at) {
        let contract_id_ok = ''
        let contract_accepted_at_ok = ''
        let contract_url_full_file_pdf = ''

        //in caso di licenza diversa da stato "Creata"
        if(contract_array !== null){
          contract_id_ok = contract_array.id
          contract_accepted_at_ok = contract_array.accepted_at

          if(contract_array.file_pdf !== null){
            contract_url_full_file_pdf = contract_array.dominio+'/download-contratto/file/'+ref_code;
          }
        }

        // Calcolo rinnovo tacito => dipende delle impostazioni Stripe
        // https://dashboard.stripe.com/settings/billing/automatic
        // su dev cambiato da 7 a 3 giorni
        let data_rinnovo_tacito_calcolata = moment(data_ends_at).subtract(7, 'days').format('DD/MM/YYYY');

        this.infoSideBar = { id: id,
          titolo: 'Licenza: '+ref_code,
          id: id,
          customer_name: customer_name,
          status_name: status_name, 
          status_colore: status_colore,
          type: type,
          price: price,
          contract_id: contract_id_ok,
          contract_accepted_at: contract_accepted_at_ok, 
          contract_url_full_file_pdf: contract_url_full_file_pdf,
          link_stripe: 'https://dashboard.stripe.com/test/customers/'+stripe_id, 
          data_rinnovo_tacito: data_rinnovo_tacito_calcolata
        };
  
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
      },
      
      updateParams(tipo_licenza, newProps) {
        console.log("GENERALE updateParams per ..... "+tipo_licenza+"...............")
        console.log(newProps)
        console.log("newProps.columnFilters.type -> "+tipo_licenza)
        console.log("Precedente this.serverParams")
        console.log(this.serverParams)
        /*
        if (typeof newProps.columnFilters !== 'undefined'){
          //iniettare sempre il filtro per lo status
          newProps.columnFilters.deleted_at = {}
          newProps.columnFilters.deleted_at.operator = '='
          newProps.columnFilters.deleted_at.value = null
        }
        */

        if(tipo_licenza == 'Abbonamento'){
          //attiva bottone "reset" filtro o ricerca
          this.resetState = 'attiva';

          this.serverParams = Object.assign({}, this.serverParams, newProps);

          
          console.log("DOPO this.serverParams")
          console.log(this.serverParams)
        } else {
          //attiva bottone "reset" filtro o ricerca
          this.resetState_oneshot = 'attiva';

          this.serverParams_oneshot = Object.assign({}, this.serverParams_oneshot, newProps);
        }
      },
      
      onPageChange_Abbonamento(params) {
        this.updateParams('Abbonamento', {page: params.currentPage});
        this.loadItems('Abbonamento');
      },
      onPageChange_OneShot(params) {
        this.updateParams('OneShot', {page: params.currentPage});
        this.loadItems('OneShot');
      },
  
      onPerPageChange_Abbonamento(params) {
        this.updateParams('Abbonamento', {perPage: params.currentPerPage});
        this.loadItems('Abbonamento');
      },
      onPerPageChange_OneShot(params) {
        this.updateParams('OneShot', {perPage: params.currentPerPage});
        this.loadItems('OneShot');
      },
  
      onSortChange_Abbonamento(params) {
        //console.log('cambio ordinamento')
        //console.log(params)
  
        let field_ricerca = '';
  
        //colonne con relazione
        if(params[0].field == 'source.text'){
          field_ricerca = 'source.name';
        } else {
          field_ricerca = params[0].field;
        }
  
        this.updateParams('Abbonamento', {
          sort: [{
            type: params[0].type,
            field: field_ricerca,
          }],
        });
        this.loadItems('Abbonamento');
      },
      onSortChange_OneShot(params) {
        let field_ricerca = '';
  
        //colonne con relazione
        if(params[0].field == 'source.text'){
          field_ricerca = 'source.name';
        } else {
          field_ricerca = params[0].field;
        }
  
        this.updateParams('OneShot', {
          sort: [{
            type: params[0].type,
            field: field_ricerca,
          }],
        });
        this.loadItems('OneShot');
      },
      
      onColumnFilter_Abbonamento(params) {
        console.log("in ->>>> onColumnFilter_Abbonamento")
        console.log(params)
        
        this.updateParams('Abbonamento', params);
        this.loadItems('Abbonamento');
      },

      onColumnFilter_OneShot(params) {
        console.log("in ->>>> onColumnFilter_OneShot")
        console.log(params)
        
        this.updateParams('OneShot', params);
        this.loadItems('OneShot');
      },
  
      loadItems(tipo = 'Abbonamento') {
        this.isLoading = true;
  
        //cambio tipo di licenza richiesta
        if(tipo == 'Abbonamento'){
          this.serverParams.columnFilters.type = "Abbonamento";

          this.$http.get('api/crm/license/list', {
            params: this.serverParams 
            }).then(response => { 
                if(response.data.statusCode == 200){
                  //ok
                  this.totalRecords = response.data.reply.meta.total;
                  this.rows = response.data.reply.data;
                  
                } else if(response.data.statusCode == 422){
                  //errori con testo di notifica ok dall'api
                  this.$swal({
                    title: 'Errore',
                    text: 'codice errore: '+response.data.reply,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      textButton: 'Chiudi',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  //tutto gli altri possibili errori
                  this.$swal({
                    title: 'Errore Generico',
                    text: 'codice errore: '+response.data.statusCode,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      textButton: 'Chiudi',
                    },
                    buttonsStyling: false,
                  })
                }
                
                //rimuovi il loading dopo il caricamento dei dati nella tabella
                this.isLoading = false;
            
            })
        } else {
          //one shot
          this.serverParams_oneshot.columnFilters.type = "OneShot";

          this.$http.get('api/crm/license/list', {
            params: this.serverParams_oneshot 
            }).then(response => { 
                if(response.data.statusCode == 200){
                  //ok
                  this.totalRecords_oneshot = response.data.reply.meta.total;
                  this.rows_oneshot = response.data.reply.data;
                  
                } else if(response.data.statusCode == 422){
                  //errori con testo di notifica ok dall'api
                  this.$swal({
                    title: 'Errore',
                    text: 'codice errore: '+response.data.reply,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      textButton: 'Chiudi',
                    },
                    buttonsStyling: false,
                  })
                } else {
                  //tutto gli altri possibili errori
                  this.$swal({
                    title: 'Errore Generico',
                    text: 'codice errore: '+response.data.statusCode,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                      textButton: 'Chiudi',
                    },
                    buttonsStyling: false,
                  })
                }
                
                //rimuovi il loading dopo il caricamento dei dati nella tabella
                this.isLoading = false;
            
            })
        }
  
      },

      cambioStatoLicenza(tipo_licenza,id_licenza,id_nuovo_stato,style_color,messaggio_conferma){
        console.log("cambioStatoLicenza ++++++\nid_licenza -> "+id_licenza+"\nid_nuovo_stato -> "+id_nuovo_stato)

        let style_button = ''
        let route_api = ''

        if(style_color == 'error'){
          style_button = 'btn btn-outline-danger'
        } else if(style_color == 'primary'){
          style_button = 'btn btn-outline-success'
        } else {
          style_button = 'btn btn-outline-'+style_color
        }

        if(id_nuovo_stato == '40'){
          //verso la "sospensione"
          route_api = 'api/crm/license/pause/'+id_licenza;
        } else if(id_nuovo_stato == '30'){
          //verso la "ri-attivazione"
          route_api = 'api/crm/license/resume/'+id_licenza;
        } else {
          //tutto il resto
          route_api = 'api/crm/license/stop/'+id_licenza+'/'+id_nuovo_stato
        }

        this.$swal({
          title: 'Sei sicuro di voler procedere?',
          text: messaggio_conferma,
          icon: style_color,
          showCancelButton: true,
          confirmButtonText: 'SI, Procedi',
          cancelButtonText: 'Annulla operazione',
          customClass: {
            confirmButton: style_button,
            cancelButton: 'btn btn-primary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$http.post(route_api)
            .then(response => { 
                console.log("risposta server --------")
                console.log(response.data)

                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                      icon: 'success',
                      title: response.data.reply.reply,
                      //text: 'operazione effettuata correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_azioni.hide();

                  //aggiorna tabella
                  this.loadItems(tipo_licenza);
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore sul cambio di stato della licenza id: '+id_licenza,
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
          }
        })
      },
      formattazioneOrario(value, stringa){
        return moment(String(value)).locale('it').format(stringa)
      },
      
    }
  }
  </script>
  
  <style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @import '@core/scss/vue/libs/vue-sweetalert.scss';
  
  .riga_sidebar{
    padding-bottom: 5px;
  }
  </style>
  